import { type DateRange } from '../models/date-range.model';

export const getLastXDaysFromToday = (amountOfDays = 180): DateRange => {
    const today = new Date();
    const dateInThePast = new Date();
    dateInThePast.setDate(dateInThePast.getDate() - amountOfDays);

    return { start: today, end: dateInThePast };
};

export const getLastXDaysFromTodayAsString = (locale: string, amountOfDays = 180): string => {
    const { start, end } = getLastXDaysFromToday(amountOfDays);
    if (!start || !end) {
        return '';
    }
    const todayAsLocaleString = start.toLocaleDateString(locale, { year: 'numeric', month: '2-digit', day: '2-digit' });
    const oneHundredEightyDaysAgo = end.toLocaleDateString(locale, { year: 'numeric', month: '2-digit', day: '2-digit' });

    return `${oneHundredEightyDaysAgo} - ${todayAsLocaleString}`;
};
