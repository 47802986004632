/* eslint-disable @typescript-eslint/naming-convention */
import { inject, Injectable } from '@angular/core';
import { ResourceTypes, RestApiClientService } from '@big-direkt/rest-api-client';
import { map, Observable } from 'rxjs';
import { AccountStatementsQueryParams } from '../models/account-statements-query-params.model';
import { AccountStatementsModel } from '../models/account-statements.model';

@Injectable({ providedIn: 'root' })
export class SalesPerformanceService {
    private readonly apiService = inject(RestApiClientService);

    public loadAccountStatements(params: AccountStatementsQueryParams): Observable<AccountStatementsModel> {
        const index_start = params.offset + 1;

        return this.apiService.load<AccountStatementsModel>(ResourceTypes.AccountStatements, undefined, {
            params: {
                indexStart: index_start,
                indexEnd: index_start + (params.elementsPerPage - 1),
                ...(params.sortField && { sortField: params.sortField }),
                ...(params.searchTerm && { searchText: params.searchTerm }),
                ...(params.sortOrder && { sortOrder: params.sortOrder }),
                ...(params.periodStart && { periodStart: params.periodStart }),
                ...(params.periodEnd && { periodEnd: params.periodEnd }),
            },
        });
    }

    public loadAllAccountStatementsToCSV(params: AccountStatementsQueryParams): Observable<string> {
        return this.loadAllAccountStatements(params).pipe(map(model => this.convertToCsv(model)));
    }

    private loadAllAccountStatements(params: AccountStatementsQueryParams): Observable<AccountStatementsModel> {
        return this.loadAccountStatements({
            ...params,
            offset: 0,
            elementsPerPage: 100_000,
        });
    }

    private convertToCsv(statements: AccountStatementsModel): string {
        const rows: string[] = ['data:text/csv;charset=utf-8,Status;Kundenname;Antragsdatum;"Mitglied seit";"Ausgezahl am";"Provision (€)"'];
        statements.items.forEach(s => {
            rows.push(
                [
                    this.fromState(s.status),
                    `"${s.firstName} ${s.lastName}"`,
                    this.fromDate(s.applicationDate),
                    this.fromDate(s.memberSince),
                    this.fromDate(s.payoffDate),
                    this.fromCurrency(s.commission),
                ].join(';'),
            );
        });

        return rows.join('\r\n');
    }

    private fromCurrency(value: number | undefined): string {
        return value ? value.toLocaleString('de-DE', { minimumFractionDigits: 2 }) : '';
    }

    private fromState(state: number | undefined): string {
        return ['unbekannt', 'in Arbeit', 'abgelehnt', 'ausgezahlt', 'zurückgefordert'][state ?? 0];
    }

    private fromDate(date: string | undefined): string {
        return date ? new Date(date).toLocaleDateString('de-DE', { year: 'numeric', month: '2-digit', day: '2-digit' }) : '';
    }
}
